<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="account-passwd"
        class="flag-svg-icon"
        transform="translate(-44.000000, -226.000000)"
        fill="#666666"
        fill-rule="nonzero"
      >
        <g id="bz-9" transform="translate(30.000000, 100.000000)">
          <g id="star" transform="translate(14.000000, 126.000000)">
            <path
              d="M2.01797929,9.03306007 C1.86310626,8.9202974 1.79689322,8.72164431 1.85313879,8.53850383 L1.92640124,8.30954261 C1.97996426,8.12859495 2.14099828,8.0004907 2.32934467,7.9889969 L8.18118226,7.52191601 L10.4248446,2.12758965 C10.5001074,1.93861509 10.6890074,1.82008128 10.8918926,1.83451929 L11.1299956,1.83451929 C11.3212688,1.83046067 11.4940804,1.94812788 11.5604124,2.12758965 L13.8132325,7.52191601 L19.6650701,7.9889969 C19.8534165,8.0004907 20.0144505,8.12859495 20.0680136,8.30954261 L20.141276,8.53850383 C20.2035803,8.71585772 20.1487526,8.91325131 20.0039089,9.03306007 L15.5898468,12.8246579 L16.9452019,18.5120546 C16.9898533,18.6941128 16.9213443,18.8852302 16.7712036,18.9974524 L16.5056273,19.1439876 C16.3507158,19.2487037 16.1477017,19.2487037 15.9927902,19.1439876 L11.0109441,16.1216995 L6.0016246,19.1714629 C5.84671312,19.276179 5.64369898,19.276179 5.4887875,19.1714629 L5.28731579,19.0340862 C5.13717512,18.921864 5.06866614,18.7307466 5.11331749,18.5486884 L6.43204145,12.8246579 L2.01797929,9.03306007 Z"
              id="Icon-color"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
