<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="logo"
        class="flag-svg-icon"
        transform="translate(-829.000000, -241.000000)"
        fill="#666666"
        fill-rule="nonzero"
      >
        <g
          id="folder_opened_labeled"
          transform="translate(829.000000, 241.000000)"
        >
          <path
            d="M20.1666667,7.33333333 C20.1666667,6.32081129 19.3458554,5.5 18.3333333,5.5 L13.2091667,5.5 C12.9686884,5.49898743 12.7382389,5.40351548 12.5675,5.23416667 L11.2658333,3.9325 C11.0950944,3.76315118 10.8646449,3.66767924 10.6241667,3.66666667 L3.66666667,3.66666667 C2.65414463,3.66666667 1.83333333,4.48747796 1.83333333,5.5 L1.83333333,16.5 C1.83333333,17.512522 2.65414463,18.3333333 3.66666667,18.3333333 L18.3333333,18.3333333 C19.3458554,18.3333333 20.1666667,17.512522 20.1666667,16.5 L20.1666667,7.33333333 Z M13.75,11.5683333 C13.75,11.3152028 13.5447972,11.11 13.2916667,11.11 L11.8066667,11.11 L11.8066667,9.625 C11.8066667,9.37186949 11.6014638,9.16666667 11.3483333,9.16666667 L10.6516667,9.16666667 C10.3985362,9.16666667 10.1933333,9.37186949 10.1933333,9.625 L10.1933333,11.11 L8.70833333,11.11 C8.45520282,11.11 8.25,11.3152028 8.25,11.5683333 L8.25,12.265 C8.25,12.5181305 8.45520282,12.7233333 8.70833333,12.7233333 L10.1933333,12.7233333 L10.1933333,14.2083333 C10.1933333,14.4614638 10.3985362,14.6666667 10.6516667,14.6666667 L11.3483333,14.6666667 C11.6014638,14.6666667 11.8066667,14.4614638 11.8066667,14.2083333 L11.8066667,12.7233333 L13.2916667,12.7233333 C13.5447972,12.7233333 13.75,12.5181305 13.75,12.265 L13.75,11.5683333 Z M18.3333333,16.5 L3.66666667,16.5 L3.66666667,7.33333333 L18.3333333,7.33333333 L18.3333333,16.5 Z"
            id="Icon-color"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
