<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="account-paawd"
        class="flag-svg-icon"
        transform="translate(-44.000000, -278.000000)"
        fill="#666666"
        fill-rule="nonzero"
      >
        <g id="bz-9" transform="translate(30.000000, 100.000000)">
          <g id="sign_out" transform="translate(14.000000, 178.000000)">
            <path
              d="M19.0483333,10.34 L14.3366667,5.6375 C14.250607,5.5507317 14.1334589,5.50192577 14.01125,5.50192577 C13.8890411,5.50192577 13.771893,5.5507317 13.6858333,5.6375 L13.035,6.28833333 C12.9487746,6.37281662 12.9001845,6.48845111 12.9001845,6.60916667 C12.9001845,6.72988222 12.9487746,6.84551671 13.035,6.93 L16.1975,10.0833333 L6.875,10.0833333 C6.62186949,10.0833333 6.41666667,10.2885362 6.41666667,10.5416667 L6.41666667,11.4583333 C6.41666667,11.7114638 6.62186949,11.9166667 6.875,11.9166667 L16.1975,11.9166667 L13.035,15.07 C12.9482317,15.1560597 12.8994258,15.2732078 12.8994258,15.3954167 C12.8994258,15.5176255 12.9482317,15.6347736 13.035,15.7208333 L13.6858333,16.3625 C13.771893,16.4492683 13.8890411,16.4980742 14.01125,16.4980742 C14.1334589,16.4980742 14.250607,16.4492683 14.3366667,16.3625 L19.0483333,11.66 C19.1773014,11.5311924 19.2498395,11.3564416 19.25,11.1741667 L19.25,10.8258333 C19.247888,10.6439537 19.1756436,10.4699105 19.0483333,10.34 Z M9.625,17.4166667 L4.58333333,17.4166667 L4.58333333,4.58333333 L9.625,4.58333333 C9.87813051,4.58333333 10.0833333,4.37813051 10.0833333,4.125 L10.0833333,3.20833333 C10.0833333,2.95520282 9.87813051,2.75 9.625,2.75 L4.58333333,2.75 C3.57081129,2.75 2.75,3.57081129 2.75,4.58333333 L2.75,17.4166667 C2.75,18.4291887 3.57081129,19.25 4.58333333,19.25 L9.625,19.25 C9.87813051,19.25 10.0833333,19.0447972 10.0833333,18.7916667 L10.0833333,17.875 C10.0833333,17.6218695 9.87813051,17.4166667 9.625,17.4166667 Z"
              id="Icon-color"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
