<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="account-info"
        class="flag-svg-icon"
        transform="translate(-44.000000, -174.000000)"
        fill="#666666"
        fill-rule="nonzero"
      >
        <g id="bz-9" transform="translate(30.000000, 100.000000)">
          <g id="lock" transform="translate(14.000000, 74.000000)">
            <path
              d="M16.5,9.16666667 L16.5,7.33333333 C16.5,4.29576721 14.0375661,1.83333333 11,1.83333333 C7.96243388,1.83333333 5.5,4.29576721 5.5,7.33333333 L5.5,9.16666667 C4.48747796,9.16666667 3.66666667,9.98747796 3.66666667,11 L3.66666667,18.3333333 C3.66666667,19.3458554 4.48747796,20.1666667 5.5,20.1666667 L16.5,20.1666667 C17.512522,20.1666667 18.3333333,19.3458554 18.3333333,18.3333333 L18.3333333,11 C18.3333333,9.98747796 17.512522,9.16666667 16.5,9.16666667 Z M7.33333333,7.33333333 C7.33333333,5.30828925 8.97495592,3.66666667 11,3.66666667 C13.0250441,3.66666667 14.6666667,5.30828925 14.6666667,7.33333333 L14.6666667,9.16666667 L7.33333333,9.16666667 L7.33333333,7.33333333 Z M12.8333333,15.5833333 C12.8333333,16.0895944 12.4229277,16.5 11.9166667,16.5 L10.0833333,16.5 C9.57707231,16.5 9.16666667,16.0895944 9.16666667,15.5833333 L9.16666667,13.75 C9.16666667,13.243739 9.57707231,12.8333333 10.0833333,12.8333333 L11.9166667,12.8333333 C12.4229277,12.8333333 12.8333333,13.243739 12.8333333,13.75 L12.8333333,15.5833333 Z"
              id="Icon-color"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
